<script>
	import { onMount } from "svelte";
    import { replace } from "svelte-spa-router";
    import { each, loop_guard } from "svelte/internal";
    import Juego from "./Juego.svelte";
    import { partidaId, group } from "../store.js";
    import { get } from "svelte/store";
    import Header from "./Header.svelte";
    import {Modal} from "sveltestrap";
    import { API_URL } from '../src/api';

    export let params = {}
    let idPrueba = params.idPrueba;
    let idPista = params.idPista;
    let grupo = get(group);
    //console.log(grupo);
    let error_message = "";
    

    if (grupo === undefined) {
        let nuevapartida_url = "#/juego/".concat(partidaId);
        replace(nuevapartida_url);
    }

    let reviewClue = false;
    if (grupo["estado_pruebas"][idPrueba]["pistas"]) {
        reviewClue = grupo["estado_pruebas"][idPrueba]["pistas"].includes(idPista)
    }
    let pistaPromise;
    let detallesPistaPromise;
	let url_apiPistasIntro = `${API_URL}/api/pruebas/` + idPrueba + "/pistas/" + idPista + "/summary"
	const urlParams = new URLSearchParams(window.location.search);
    

	const getPistaInfo = async() => {
		var response = await fetch(url_apiPistasIntro);
		var result = await response.json();
		return result;
	}

	pistaPromise = getPistaInfo();

    let gameStatus;
    let gameStatusPromise;
    const getGameStatus = async() => {
        if (grupo.id) {
            let apiStatus = `${API_URL}/api/partidas/` + $partidaId + "/equipos/" + grupo.id + "/status/";
	        console.log(apiStatus);
            var response = await fetch(apiStatus);
            if (response.status === 200) {
                //partidaId.set(idPartida)
                gameStatus = await response.json();
            } else {
                console.log("Error api status")
                throw new Error(response.status)
            } 
        } else {
            console.log("No tengo equipo");
        }
    }

    gameStatusPromise = getGameStatus().finally(
        () => {
            console.log("getGameStatus Finally");
            $group= gameStatus;
            group.set(gameStatus);
            console.log(gameStatus);
        }
    )

    const optional = x => x || '';

    let pendingConfirmation=true;
    let showClue = false;

    function handleClick(confirmation) {
        pendingConfirmation=false;

        if (confirmation) {
            showClue=true;
            let equipo = grupo.id;
            const obtainClue= async() => {
                const apiPistas = `${API_URL}/api/pruebas/` + idPrueba + "/pistas/" + idPista + "/details";
                const settings = {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        equipo: equipo
                    })
                };

                const response = await fetch(apiPistas, settings);
                    
                    if (response.status === 200) {
                        return await response.json();
                    } else {
                        return Promise.reject(await response.json());
                    } 
            }

            detallesPistaPromise = obtainClue();
        } else {
            replace ("#/prueba/" + idPrueba);
        }
       
    }    

    if (reviewClue) {
        handleClick(true);
    }
    
    let open = false;
    const toggle = () => (open = !open);
</script>


<!--

-->
{#await pistaPromise}
	<b>Carregant...</b>
{:then pista}
    {#await gameStatusPromise}
    <b>Carregant....</b>
    {:then}

        {#if gameStatus.estado_partida["next_pista"]}
        <Header bind:open={open} time={gameStatus.tiempo_juego} pista_url={`#/juego/${$partidaId}/pista/${gameStatus.estado_partida["next_pista"]}`}></Header>
        {:else}
            {#if gameStatus.estado_partida["pistas"]}
                <Header bind:open={open} time={gameStatus.tiempo_juego} pista_url={`#/juego/${$partidaId}/pista/${gameStatus.estado_partida["pistas"][0]}`}></Header>
            {/if}
        {/if}

        <Modal body header="Instruccions" isOpen={open} {toggle}>
            {@html gameStatus.instrucciones_juego} 
        </Modal>
        {#if !reviewClue & pendingConfirmation}

            <div class='envelope'>
                <p class="typewrite">La següent pista sumarà <u>{pista.penalizacion} minuts</u> al vostre temps. Voleu obtenir la pista?</p>
            </div>
            
            <div class="d-grid gap-2 col-10 mx-auto">
                <button on:click|trusted={() => handleClick(false) } class="btn btn-danger" type="button">
                    <i class="zmdi zmdi-arrow-left"></i>  No, volem continuar investigant
                </button>
                <button on:click|trusted={ () => handleClick(true) } class="btn btn-success" type="button">
                    Sí, la necessitem!  <i class="zmdi zmdi-arrow-right"></i>
                </button>
            </div>

            
        {:else}
            {#if showClue || reviewClue}
                {#await detallesPistaPromise}
                    <b> Establint comunicació amb central...</b>
                {:then detalles}
                    <div class=letter>
                        <p class="typewrite">{@html detalles.texto_pista}</p>
                        {#if detalles.tipo === 1}
                            <div>
                                <img class="img-pista" src=".{detalles.fichero_soporte}" alt="Pista imagen"/>
                            </div>
                        {/if}
                    </div>
                {:catch error}
                    <b>Error establint connexió amb central. No s'ha pogut aconseguir el detall.</b>
                {/await}
                <div class="d-grid gap-2 col-10 mx-auto spacer">
                    <a href={`#/prueba/${idPrueba}`} class="btn btn-success">
                        <i class="zmdi zmdi-arrow-left"></i>  Tornar a la investigació
                    </a>
                </div>  
            {:else}
                <h1>Continua investigant</h1>
            {/if}
        {/if}
    {:catch err}
        <b>{idPrueba}</b>
    {/await}
           
{:catch err}
	<b>{idPrueba}</b>
{/await}


<style>
     p.typewrite {
        font-family: 'Courier Prime', monospace;
    }

    :global(body) {
        background-color: #fccc77;
        min-height: 100vh;
    }

    .img-pista {
        display: block;
        margin: 0px 0px 0px -25px;
        max-width: 116%;
        transform: rotate(2deg);
        border-radius: 5px;
        border: 10px solid #fff;
        box-shadow: 0 5px 10px rgba(0,0,0,0.3);
        padding: 5px;
        margin-bottom: 10px;
        background-color: #fff;
    }

    .envelope {
    overflow: hidden;
    position: relative;
    margin: 30px auto;
    width: 20em; height: 10em;
    border-radius: .25em;
    background: lemonchiffon;
    }
    .envelope:before, .envelope:after {
    position: absolute;
    top: -.25em;
    width: 40%; height: 30%;
    content: '';
    }
    .envelope:before {
    left: 1em;
    border-radius: 0 0 0 .25em;
    box-shadow: -4px 3px 2px 0px rgb(0 0 0 / 30%);
    transform: skewX(37.5deg);
    }
    .envelope:after {
    right: 1em;
    border-radius: 0 0 .25em 0;
    transform: skewX(-37.5deg);
    box-shadow: 4px 3px 2px 0px rgb(0 0 0 / 30%);
    }

    .envelope p {
    margin: 60px 15px 15px 15px;
    }

    .letter {
	background: #F4F0E8;
	box-shadow: 0 0 10px rgba(0,0,0,0.3);
	margin: 12px auto 0;
	/*max-width: 550px;*/
	min-height: 300px;
	padding: 24px;
	position: relative;
	width: 90%;
    margin-bottom: 14px;
}

.letter:before, .letter:after {
	content: "";
	height: 98%;
	position: absolute;
	width: 100%;
	z-index: -1;
}

.letter:before {
	background: #fafafa;
	box-shadow: 0 0 8px rgba(0,0,0,0.2);
	left: -5px;
	top: 4px;
	transform: rotate(-2.5deg);
}

.letter:after {
background: #f6f6f6;
box-shadow: 0 0 3px rgba(0,0,0,0.2);
right: -3px;
top: 1px;
transform: rotate(1.4deg);
}

.spacer {
    margin-top:30px;
}
</style>