<script>

import {Collapse, Card, Button, Modal} from "sveltestrap";
import { partidaId} from "../store.js";


export let open;
export let time = 0;
export let pista_url = "";
export let final_time = -1;
export let penalizacion = 0;

time = parseInt(time)

const updateTime = () => {
    if (time>=0) {
      time = parseInt(time) + 1;
    }
    
  };

  // Actualizar el tiempo cada segundo
  const interval = setInterval(updateTime, 1000);


// Función para convertir segundos a hora:minuto:segundo
const secondsToTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

const toggle = () => (open = !open);

</script>

<header class="navbar navbar-expand navbar-light bg-light">
    <div class="container-fluid">
      <a class="navbar-brand" href="#/juego/{$partidaId}">
        <img src="static/frontend/img/logo-3.png" alt="Logo">
      </a>
      <ul class="navbar-nav ms-auto">
      
            {#if final_time<=0}
              {#if time>=-1}
                <li class="">
                    <i class="icono bi bi-question-circle" on:click={toggle}></i>
                </li>
              {/if}
            
              {#if time>=0}
              
                  <li class="navbar-nav">
                    <a href="#/juego/{$partidaId}">
                      <i class="icono bi bi-folder"></i>
                    </a>
                  </li>

                  <li class="navbar-nav">
                    <a href="{pista_url}">
                      <i class="icono bi bi-map"></i>
                    </a>
                  </li>
                
                <li class="navbar-nav">
                    <i class="icono bi bi-clock"></i>
                </li>

                <li class="navbar-nav">
                  <span class="mt-1 timer">{secondsToTime(time+penalizacion*60)}</span>
                </li>
              {/if}
            {/if}

            {#if final_time>0}
              <li class="navbar-nav">
                <i class="icono bi bi-flag-fill"></i>
                <span class="mt-1 timer">{secondsToTime(parseInt(final_time))}</span>
              </li>

              
              <li class="navbar-nav">
                <a href="{pista_url}"><i class="icono bi bi-award-fill"></i>
                <span class="mt-1 timer">Rànquing</span></a>
              </li>
            {/if}
      </ul>
    </div>
  </header>
  
  
  
  


  <style>
header {
  padding: 0.1rem;
  background-color: #222222 !important;
  color: #F2F2F2;
}

.navbar-brand {
    margin: 0;
    padding: 0;
}
.navbar-brand img {
  height: 2.5rem;
  
}

.navbar-nav .nav-link {
  padding: 0.5rem 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.navbar-nav .nav-link .icon-lg {
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
}

.navbar-nav .nav-link span {
  font-size: 1rem;
  text-align: center;
}

@media screen and (max-width: 800px) {
  .navbar-nav .nav-link .icon-lg {
    font-size: 1.5rem;
  }
}
.icono {
    padding: 0 5px 0 10px;
}
span {
  display: inline-block;
}

span.timer {
  margin-top: .5rem !important;
}

.timer {
    min-width: 50px !important;
    font-family: 'Special Elite', cursive;
    font-size: 0.7rem;
}

.bi {
  color: #F2F2F2;
}

    a {
        text-decoration:none;
        padding:0px;
        margin:0px;
        color: inherit;
    }

  </style>