<script>
    import { onMount, onDestroy } from "svelte";
    
    let claseBody = "main-background";
    $: document.body.classList.add(claseBody);
  
    //Eliminar la clase del body cuando el componente sea destruido
    onDestroy(() => {
        document.body.classList.remove(claseBody);
    });
</script>

<div class="container">
    <img src="static/frontend/img/logo-vertical.png" class="img-fluid" alt="Logo">
</div>


<style>
    :global(body.main-background)  {
        background: linear-gradient(#5F606D, #5F606D, #222222);
        min-height: 100vh;
    }

    .container {
        text-align: center;
    }
</style>