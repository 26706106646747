<script>
	import { onMount, onDestroy } from "svelte";
    import { replace } from "svelte-spa-router";
    import { each, loop_guard } from "svelte/internal";
    import { get } from "svelte/store";
    import { partidaId, groups, group} from "../store.js";
    import {Button, Modal} from "sveltestrap";

    import SolucionPrueba from "./SolucionPrueba.svelte";
    import Header from "./Header.svelte";
    import { API_URL } from '../src/api';
    
    //Pasar parámetros entre componentes:
    //https://svelte.dev/tutorial/context-api
    
    export let params = {}
    const idPartida = params.idPartida;
    
    let claseBody = "main-background";
    $: document.body.classList.add(claseBody);
  
    //Eliminar la clase del body cuando el componente sea destruido
    onDestroy(() => {
        document.body.classList.remove(claseBody);
    });


    let idEquipo;
    let nuevaPartida=true;

    let dictGroups = get(groups);
    //console.log(dictGroups);
    //console.log(idPartida);
    //console.log(dictGroups[idPartida]);
    if (dictGroups[idPartida]) {
        group.set(dictGroups[idPartida]);
        console.log("GRUPO:");
        console.log(get(group));
        nuevaPartida=false;
        idEquipo=dictGroups[idPartida]["id"];
    } 

    let gamePromise;
    let gameStatusPromise;
    let gameStatus;
    let gameSolved=false;
	let apiPartidas = `${API_URL}/api/partidas/`
    

    const urlParams = new URLSearchParams(window.location.search);
	let url_api_partidas = apiPartidas.concat(idPartida)
    let ranking_url = "#/ranking/".concat(idPartida)
    let replace_url = ""

    
    function isGameSolved(dictStatusPruebas) {
        var solved = true;
        for(const [key,value] of Object.entries(dictStatusPruebas)) {
            solved &= value.estado;
        }
        return solved;
    }

	const getGameInfo = async() => {
		var response = await fetch(url_api_partidas);
        if (response.status === 200) {
            partidaId.set(idPartida); 
            return await response.json();
        } else {
            if (response.status === 403) {
                replace_url = ranking_url;
                throw new Error(response.status)
            } else {
                replace_url = "#/";
                throw new Error(response.status)
            }
        } 
	} 

    const getGameStatus = async() => {
        if (idEquipo) {
            let apiStatus = `${API_URL}/api/partidas/` + idPartida + "/equipos/" + idEquipo + "/status/";
	        console.log(apiStatus);
            var response = await fetch(apiStatus);
            if (response.status === 200) {
                gameStatus = await response.json();
            } else {
                console.log("Error api status")
                throw new Error(response.status)
            } 
        }
    }

    gamePromise = getGameInfo().finally(
        () =>  { if (replace_url !== "") {
                replace(replace_url)
            }   
        }      
    );
 

    if (idEquipo) {
        gameStatusPromise = getGameStatus().finally(
            () => {
                console.log("getGameStatus Finally");
                console.log(gameStatus)
                group.set(gameStatus);
                gameSolved=isGameSolved(gameStatus.estado_pruebas); 
            }
        )
    }

    let equipo;
    let nombre;
    let error_message="";
    
    
    function handleSubmit(event) {
        event.preventDefault();
        equipo = event.target.elements.key_id.value;
        nombre = event.target.elements.equipo.value;
        if (equipo.length <1 ){
            error_message = "Longitud incorrecta";
        } else {
            const postEquipoInfo = async() => {
                let apiEquipo = `${API_URL}/api/partidas/` + idPartida + "/equipos/" + equipo;
                const settings = {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        nombre: nombre
                    })
                }
                const response = await fetch(apiEquipo, settings);
                
                if (response.status === 200) {
                    return await response.json();
                } else {
                    return Promise.reject(await response.json());
                    //error_message = response.text();
                    //sreturn await response.json();
                } 
            }
              
           postEquipoInfo().then( function (equipo_info) {
                console.log(equipo_info);
                nuevaPartida = false;
                console.log("Equipo definido");
                partidaId.set(idPartida);
                console.log(idPartida);
                console.log(equipo_info.id)
                groups.update(dict=> {
                    dict[idPartida] = equipo_info;
                    return dict;
                });
                
                location.reload();
            }, function(error){
                error_message = error.error;
            });
        }
    }   
    let open = false;
    const toggle = () => (open = !open);

    let screenHeight = window.innerHeight;
    let halfScreenHeight = screenHeight / 2;

    function goToRanking(go){
        if (go) {
            replace(ranking_url);
        }
        
    }

</script>

{#await gamePromise}
	<b>Carregant....</b>
{:then game}
    {#if nuevaPartida}
        
        <Header bind:open={open} time=-1></Header>
        <div>       
            <div class=letter>
                <p class="typewrite"><b>{game.nombre}</b><br/>
                    <b>{game.ubicacion} - {`${new Date(game.fecha_juego).toLocaleDateString()}`}</b></p>
                <p class="typewrite">{@html game.juego.texto_juego}</p>
            </div>
                    
            <Modal body header="Instruccions" isOpen={open} {toggle}>
                {@html game.juego.instrucciones} 
            </Modal>        
        </div>
        {#if error_message}
            <div class="error">Error {error_message}</div>
        {/if}
        <form on:submit={handleSubmit}>
            <div class="d-grid gap-2 col-10 mx-auto spacer">
                <input class="form-control" type="text" maxlength="40" name="equipo" required placeholder="Nom d'equip"/>
                <input class="form-control" type="text" maxlength="16" name="key_id" required placeholder="Clau" />
                <button class="btn btn-primary" type="submit"><i class="zmdi zmdi-search"></i> Iniciar investigació</button>
            </div> 

        </form>
        <div style="height: {halfScreenHeight}px;"></div>
    {:else}
        {#await gameStatusPromise}
            <b>Carregant....</b>
        {:then}
        
            {#if !gameSolved}
                {#if gameStatus.estado_partida["next_pista"]}
                    <Header bind:open={open} time={gameStatus.tiempo_juego} pista_url={`#/juego/${idPartida}/pista/${gameStatus.estado_partida["next_pista"]}`}></Header>
                {:else}
                    {#if gameStatus.estado_partida["pistas"]}
                        <Header bind:open={open} time={gameStatus.tiempo_juego} pista_url={`#/juego/${idPartida}/pista/${gameStatus.estado_partida["pistas"][0]}`}></Header>
                    {/if}
                {/if}
                
                    <div>
                        <div class=letter>
                            <p class="typewrite"><b>{game.nombre}</b><br/>
                                <b>{game.ubicacion} - {`${new Date(game.fecha_juego).toLocaleDateString()}`}</b></p>
                            <p class="typewrite">{@html game.juego.texto_juego}</p>
                        </div>
                        
                        <Modal body header="Instruccions" isOpen={open} {toggle}>
                            {@html game.juego.instrucciones} 
                        </Modal>
                        
                        <div class="container" id="folder">
                            {#each game.juego.juegos as prueba, i}
                                {#if i==0}
                                    <div class="row">
                                        <div class="col-4 tab tab-left">
                                        <div class="tab-content">
                                            <a href={`#/prueba/${prueba.pruebaid}`}><span class="label">{prueba.prueba.nombre} {#if gameStatus.estado_pruebas[prueba.prueba.id]["estado"]}<i class="done bi bi-check"></i> {/if}</span></a>
                                        </div>
                                        </div>
                                    </div>
                                {:else if i%3 + 1  == 1}
                                    <div class="row row-extra">
                                        <div class="col-4 tab tab-left">
                                        <div class="tab-content">
                                            <a href={`#/prueba/${prueba.pruebaid}`}><span class="label">{prueba.prueba.nombre} {#if gameStatus.estado_pruebas[prueba.prueba.id]["estado"]}<i class="done bi bi-check"></i> {/if}</span></a>
                                        </div>
                                        </div>
                                        <div class="col-4 tab-none-shadow">
                                        </div>
                                        <div class="col-4 tab-none">
                                        </div>
                                    </div>
                                {:else if i%3 + 1 == 2}
                                    <div class="row row-extra">
                                        <div class="col-4 tab-none">
                                        </div>
                                        <div class="col-4 tab tab-center">
                                        <div class="tab-content">
                                            <a href={`#/prueba/${prueba.pruebaid}`}><span class="label">{prueba.prueba.nombre} {#if gameStatus.estado_pruebas[prueba.prueba.id]["estado"]}<i class="done bi bi-check"></i> {/if}</span></a>
                                        </div>
                                        </div>
                                        <div class="col-4 tab-none-shadow">
                                        </div>
                                    </div>
                                {:else}
                                    <div class="row row-extra">
                                        <div class="col-4 tab-none-shadow">
                                        </div>
                                        <div class="col-4 tab-center">
                                        </div>
                                        <div class="col-4 tab tab-right">
                                        <div class="tab-content">
                                            <a href={`#/prueba/${prueba.pruebaid}`}><span class="label">{prueba.prueba.nombre} {#if gameStatus.estado_pruebas[prueba.prueba.id]["estado"]}<i class="done bi bi-check"></i> {/if}</span></a>
                                        </div>
                                        </div>
                                    </div>
                                {/if}
                            {/each}
                            <div class="row">
                                <div class="col-12 tab-full">
                                </div>
                            </div>
                        </div>
                        
                    </div>
            {:else}
                <Header final_time={gameStatus.tiempo_juego} pista_url={`#/ranking/${idPartida}`}></Header>
                
                <!--<div class="container victory bg-light rounded mt-3 mx-auto">
                    <p>{@html gameStatus.estado_partida["solucion"]}</p>
                    <p style="text-align:center">
                        <Button color="success" on:click={toggle}>
                            <i class="zmdi zmdi-bookmark"></i> Veure referències  
                        </Button>
                    </p>
                </div>-->
                <div class=letter>
                    <p class="typewrite">{@html gameStatus.estado_partida["solucion"]}</p>
                </div>
                <p style="text-align:center">
                    <Button color="success" on:click={toggle}>
                        <i class="zmdi zmdi-bookmark"></i> Veure referències  
                    </Button>
                </p>
                <p style="text-align:center">
                    <button on:click|trusted={ () => goToRanking(true) } class="btn btn-success ranking" type="button">
                        <i class="icono bi bi-award-fill"></i>Rànquing 
                    </button>
                </p>
                

                <Modal isOpen={open} {toggle} body header="📚 Referències literàries">
                    {@html gameStatus.estado_partida["referencias"]} 
                </Modal>
                
            {/if}
        {:catch err}
            <b>{err}</b>
            <p>Tornem a home</p>
            <b>{err}</b>
        {/await}
    {/if}
    
    {:catch err}
    <b>{err}</b>
    <p>Tornem a home</p>
    <b>{err}</b>
{/await}

<style>

    :global(body.main-background)  {
        background: linear-gradient(#5F606D, #5F606D, #222222);
        min-height: 100vh;
    }

    h1, h4 {
        font-family: "Rowdies";
        color: #F2F2F2;
        text-align: center;
    }

    .btn {
        background-color: #A40018;
        border-color: #A40018;
    }

    a {
        text-decoration:none;
    }

    .typewrite {
	    /*font-family: 'Special Elite', cursive;*/
        font-family: 'Courier Prime', monospace;
        font-size: 12px;
    }

    .row-extra {
        background-color: #fccc77;
    }

    .row-extra:before {
        background: #fafafa;
        box-shadow: 0 0 8px rgba(0,0,0,0.2);
        left: -5px;
        top: 4px;
        transform: rotate(-2.5deg);
    }

    .row-extra:after {
        background: #f6f6f6;
        box-shadow: 0 0 3px rgba(0,0,0,0.2);
        right: -3px;
        top: 1px;
        transform: rotate(1.4deg);
    }

    .tab {
        position: relative;
        width: calc(100% / 3);
        height: 40px;
        background-color: #fccc77;
        border-radius: 10px 10px 0 0;
        box-shadow: 0px -12px 9px -1px rgba(0, 0, 0, 0.3);
        -webkit-box-shadow: 3-5x -5px 2px 3px rgba(0, 0, 0, 1);
        -moz-box-shadow: 3px -5px 3px 2px rgba(0, 0, 0, 1);
        margin-right: 0px;
        padding-left: 0px !important;
        padding-right: 0px !important;
        z-index: 100;
    }

    .tab-none-shadow {
        box-shadow: 0px -12px 9px -10px rgba(0, 0, 0, 0.3);
    }


    .tab-full {
        position: relative;
        height: 160px;
        background: linear-gradient(rgba(252,204,119,1),rgba(252,204,119,1),rgba(252,204,119,0));
        border-radius: 0px;
        box-shadow: 0px -12px 9px -10px rgba(0, 0, 0, 0.3);
        color:#ff0000;
    }

    /* ETIQUETA */
    .tab-content {
        position: absolute;
        top: 0px;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #f4f0e8;
        border-radius: 5px;
        padding: 4px 2px;
        margin: 5px 5px 5px 5px;
        text-align: center;
    }

    .label {
        font-family: 'Handlee', cursive;
        font-size: 16px;
        font-style: italic;
        font-weight: bold;
        color: #5F606D;
    }

    .letter {
  background: #F4F0E8;
  box-shadow: 0 0 10px rgba(0,0,0,0.3);
  margin: 12px auto 0;
  max-width: 550px;
  min-height: 100px;
  padding-top: 20px;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 14px;
  margin-bottom: 14px;
  position: relative;
  width: 90%;
}
.letter:before, .letter:after {
  content: "";
  height: 98%;
  position: absolute;
  width: 90%;
  z-index: -1;
}
.letter:before {
  background: #fafafa;
  box-shadow: 0 0 8px rgba(0,0,0,0.2);
  left: -5px;
  top: 4px;
  transform: rotate(-2.5deg);
}
.letter:after {
  background: #f6f6f6;
  box-shadow: 0 0 3px rgba(0,0,0,0.2);
  right: -3px;
  top: 1px;
  transform: rotate(1.4deg);
}

.error {
    color: #A40018
}

.done {
    color: green;
    font-weight: 700;
}

.spacer {
    margin-top: 30px;
}

.victory {
    --bs-bg-opacity: 0.6;
    background-color: rgba(0, 0, 0, 0.6);
    padding: 10px;
}

:global(.modal-body) {
    margin-bottom: 30px;
}

.ranking {
    background-color: #198754 !important;
    border-color: #198754 !important;
}
</style>
