<script>
    import Header from "./Header.svelte";
    import { onDestroy } from 'svelte';
    import { Tooltip } from 'sveltestrap';
    import { API_URL } from '../src/api';

    export let params = {}
    const idPartida = params.idPartida;

    let claseBody = "main-background";
    $: document.body.classList.add(claseBody);
  
    //Eliminar la clase del body cuando el componente sea destruido
    onDestroy(() => {
        document.body.classList.remove(claseBody);
    });


    let error_message;
    let url_api_rankig = `${API_URL}/api/live/`.concat(idPartida);
    let replace_url = "";
    let ranking_list;

    function toTimeString(totalSeconds, penalizacion=0) {
        const totalMs = totalSeconds * 1000 + penalizacion * 1000;
        const result = new Date(totalMs).toISOString().slice(11, 19);

        return result;
    }

    const getRankingInfo = async() => {
		var response = await fetch(url_api_rankig);
        if (response.status === 200) {
            ranking_list = await response.json();
        } else {
            console.log(response.status);
            replace_url = "/";
            throw new Error(response.status);
        } 
	} 

    let rankingPromise;
    rankingPromise = getRankingInfo().finally(
        () => {});
    
    const intervalLive = setInterval( () => {
        rankingPromise = getRankingInfo().finally(
        () => {});
    },30000);

    onDestroy(() => clearInterval(intervalLive));
    
</script>


{#if error_message}
    <h3>{error_message}</h3>
{:else}
{#await rankingPromise}
    <b>Carregant ranking...</b>
{:then}
<Header time=-2></Header>
<div class="ranking">
<h1>{ranking_list.partida["nombre"]}</h1>
<h4>{ranking_list.partida["ubicacion"]} - {`${new Date(ranking_list.partida["fecha_juego"]).toLocaleDateString()}`}</h4>

    <table class="table">
        <thead>
            <th scope="col">Equip</th>
            <th scope="col">Inici</th>
            {#each Object.entries(ranking_list.ranking[0].estado_pruebas) as [key,value], index(key)}
            <th scope="col">{value["nombre"]}</th>
            {/each}
            <th scope="col">Final</th>
            <th scope="col">Durada</th>
            <th scope="col">Pistes</th>
            <th scope="col">Errors</th>
            <th scope="col">Penalització</th>
        </thead>
        <tbody>
        {#each ranking_list.ranking as equipo, i}
            <tr>
                <td>{equipo.nombre}</td>
                <td>{equipo.started_at}</td>
                {#each Object.entries(equipo.estado_pruebas) as [key,value], index(key)}
                    {#if value["correct"]}
                        <td class="text-success">
                        <div id={`p-${key}-${i}`}>{value["count"]}</div>
                        <Tooltip target={`p-${key}-${i}`} placement="bottom">
                            {value["tries"]}
                        </Tooltip></td>
                    {:else if value["count"]>0}
                        <td class="text-danger">
                        <div id={`p-${key}-${i}`}>{value["count"]}</div>
                        <Tooltip target={`p-${key}-${i}`} placement="bottom">
                            {value["tries"]}
                        </Tooltip></td>
                    {:else}
                        <td>{value["count"]}</td>
                    {/if}
                {/each}
                {#if equipo.finish_at}
                    <td>{equipo.finish_at}</td>
                    <td>{toTimeString(equipo.gametime, equipo.penalizacion_total*60)}</td>
                {:else}
                    <td>-</td>
                    <td>-</td>
                {/if}
                <td>{equipo.num_pistas}</td>
                <td>{equipo.num_fallos}</td>
                <td>+{toTimeString(equipo.penalizacion_total*60)}</td>
            </tr>
        {/each}
        </tbody>
    </table>
</div>
{/await}
{/if}


<style>
    :global(body.main-background)  {
        background: linear-gradient(#5F606D, #5F606D, #222222);
        min-height: 100vh;
    }
    
    table {
        color: #F2F2F2;
    }

    .ranking, thead {
        font-family: "Rowdies";
        color: #F2F2F2;
        text-align: center;
    }

    table {
        margin-top: 20px;   
    }
</style>

