<script>
    import { onMount, onDestroy } from "svelte";
    import Header from "./Header.svelte";
    import { API_URL } from '../src/api';

    export let params = {}
    const idPartida = params.idPartida;

    let claseBody = "main-background";
    $: document.body.classList.add(claseBody);
  
    //Eliminar la clase del body cuando el componente sea destruido
    onDestroy(() => {
        document.body.classList.remove(claseBody);
    });

    let error_message;
    let url_api_rankig = `${API_URL}/api/ranking/`.concat(idPartida);
    let replace_url = "";
    let ranking_list;

    function toTimeString(totalSeconds, penalizacion=0) {
        const totalMs = totalSeconds * 1000 + penalizacion * 1000;
        const result = new Date(totalMs).toISOString().slice(11, 19);
        return result;
    }

    const getRankingInfo = async() => {
		var response = await fetch(url_api_rankig);
        if (response.status === 200) {
            ranking_list = await response.json();
        } else {
            console.log(response.status);
            replace_url = "/";
            throw new Error(response.status);
        } 
	} 

    let rankingPromise;
    rankingPromise = getRankingInfo().finally(
        () => {
            ranking_list.ranking = ranking_list.ranking.sort((a,b) => {
                if ( (parseFloat(a.gametime) + a.penalizacion_total*60) < (parseFloat(b.gametime) + b.penalizacion_total*60)) {
                    return -1;
                }
                if ( (parseFloat(a.gametime) + a.penalizacion_total*60) > (parseFloat(b.gametime) + b.penalizacion_total*60)) {
                    return 1;
                }
                return 0;
            });
        });
</script>

{#if error_message}
    <h3>{error_message}</h3>
{:else}
{#await rankingPromise}
    <b>Carregant ranking...</b>
{:then}

<Header time=-2></Header>
<div class="ranking">
<h1>{ranking_list.partida["nombre"]}</h1>
<h4>{ranking_list.partida["ubicacion"]} - {`${new Date(ranking_list.partida["fecha_juego"]).toLocaleDateString()}`}</h4>

<table class="table">
    <thead>
        <th scope="col">#</th>
        <th scope="col">Equip</th>
        <th scope="col">Durada</th>
        <th scope="col">Pistes</th>
    </thead>
    <tbody>
        {#each ranking_list.ranking as equipo, i}
            <tr>
                <td>{i+1}</td>
                <td>{equipo.nombre}</td>
                <td>{toTimeString(equipo.gametime,equipo.penalizacion_total*60)}</td>
                <td>{equipo.num_pistas}</td>
            </tr>
        {/each}
    </tbody>
    </table>
</div>
{/await}
{/if}

<style>
    :global(body.main-background)  {
        background: linear-gradient(#5F606D, #5F606D, #222222);
        min-height: 100vh;
    }   

    .ranking, thead {
        font-family: "Rowdies";
        color: #F2F2F2;
        text-align: center;
    }

    table {
        margin-top: 20px;   
        color: #F2F2F2;
    }
</style>