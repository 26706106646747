import Home from "./components/Home.svelte";
import Juego from "./components/Juego.svelte";
import Prueba from "./components/Prueba.svelte";
import Pista from "./components/Pista.svelte";
import PistaPartida from "./components/PistaPartida.svelte";
import Ranking from "./components/Ranking.svelte";
import Live from "./components/Live.svelte";
import NotFound from "./components/NotFound.svelte";

export const routes = {
    "/": Home,
    "/juego/:idPartida": Juego,
    "/juego/:idPartida/pista/:idPista": PistaPartida,
    "/juego/:idPartida/:anchor": Juego,
    "/ranking/:idPartida": Ranking,
    "/live/:idPartida": Live,
    "/prueba/:idPrueba" : Prueba,
    "/prueba/:idPrueba/pista/:idPista": Pista,
    "*": NotFound 
}