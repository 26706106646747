<script>
	import { onMount } from "svelte";
    import { replace } from "svelte-spa-router";
    import { each, loop_guard } from "svelte/internal";
    import Juego from "./Juego.svelte";
    import { partidaId, group } from "../store.js";
    import { get } from "svelte/store";
    import Header from "./Header.svelte";
    import SolucionPrueba from "./SolucionPrueba.svelte";
    import {Modal} from "sveltestrap";
    import { API_URL } from '../src/api';

    export let params = {}
    let idPrueba = params.idPrueba;
    let grupo = get(group);
    //console.log(grupo);
    let error_message = "";
    let is_wrong = false;
    
    
    if (grupo === undefined) {
        let nuevapartida_url = "#/juego/".concat(partidaId);
        replace(nuevapartida_url);
    }

    let pruebaPromise;
    let gameStatus;
    let gameStatusPromise;
    let resolved = false;
	let apiPruebas = `${API_URL}/api/pruebas/`
	const urlParams = new URLSearchParams(window.location.search);
    let pistasPendientes = 0;
    let pistasNoDisponibles;
    
	
	let url_api_pruebas = apiPruebas.concat(idPrueba)
	const getPruebaInfo = async() => {
		var response = await fetch(url_api_pruebas);
		var result = await response.json();
		return result;
	}

	pruebaPromise = getPruebaInfo();
    const optional = x => x || '';

    let errorEnvio="";
    const getGameStatus = async() => {
        if (grupo.id) {
            let apiStatus = `${API_URL}/api/partidas/` + $partidaId + "/equipos/" + grupo.id + "/status/";
	        console.log(apiStatus);
            var response = await fetch(apiStatus);
            if (response.status === 200) {
                //partidaId.set(idPartida)
                gameStatus = await response.json();
                
                pistasPendientes = gameStatus.estado_pruebas[idPrueba]["num_pistas"];
                let pistasUsadas = 0; 
                if (gameStatus.estado_pruebas[idPrueba]["pistas"] != undefined) {
                    pistasUsadas = gameStatus.estado_pruebas[idPrueba]["pistas"].length;
                } 
                pistasPendientes -= pistasUsadas;
                if (pistasPendientes>0) pistasPendientes--;
                pistasNoDisponibles = Array(pistasPendientes).fill(0);
            } else {
                console.log("Error api status")
                throw new Error(response.status)
            } 
        } else {
            console.log("No tengo equipo");
        }
    }

    gameStatusPromise = getGameStatus().finally(
        () => {
            console.log("getGameStatus Finally");
            $group= gameStatus;
            group.set(gameStatus);
            console.log(gameStatus);
        }
    )

    let ultimaRespuesta = "";
    let penalizacion = 0;

    function addPenalizacion(){
        penalizacion++;
    }

    function handleSubmit(event) {
        //event.preventDefault();
        let partida=$partidaId;
        let equipo = grupo.id;
        const registerAnswer = async() => {
            let respuesta = event.target.elements.respuesta.value;
            ultimaRespuesta = respuesta;
            const apiRespuesta = `${API_URL}/api/respuestas/`;
            const settings = {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    prueba: idPrueba,
                    partida: partida,
                    equipo: equipo,
                    answer: respuesta,
                    correct: false
                })
            };

            const response = await fetch(apiRespuesta, settings);
                
                if (response.status === 201) {
                    return await response.json();
                } else {
                    return Promise.reject(await response.json());
                    //error_message = response.text();
                    //sreturn await response.json();
                } 
        }

        registerAnswer().then( function (respuesta) {
                console.log(respuesta);
                if (respuesta.correct) {
                    resolved = true;
                    is_wrong = false;
                    window.scrollTo(0,0);
                } else {
                    is_wrong = true;
                    event.target.reset();
                    //console.log(respuesta.mensaje_error);
                    if (respuesta.mensaje_error != null) {

                        error_message = respuesta.mensaje_error;
                        addPenalizacion();
                    } 
                    else {
                        error_message = "L'agencia està segura de que aquesta no és la resposta correcta. Continua investigant."
                    }
                }
            }, function(error){
                error_message = error.error;
                console.log(error);
            });
    }    

    function backToHome() {
        replace ("#/juego/" + $partidaId);
    }    
	let open = false;
    const toggle = () => (open = !open);

    let screenHeight = window.innerHeight;
    let halfScreenHeight = screenHeight / 2;

    onMount(() => window.scrollTo(0,0));
</script>


{#await pruebaPromise}
	<b>Carregant....</b>
{:then prueba}
    {#await gameStatusPromise}
        <b>Carregant....</b>
    {:then}
    
        {#if gameStatus.estado_partida["next_pista"]}
            <Header bind:open={open} bind:penalizacion={penalizacion} time={gameStatus.tiempo_juego} pista_url={`#/juego/${$partidaId}/pista/${gameStatus.estado_partida["next_pista"]}`}></Header>
        {:else}
            {#if gameStatus.estado_partida["pistas"]}
                <Header bind:open={open} bind:penalizacion={penalizacion} time={gameStatus.tiempo_juego} pista_url={`#/juego/${$partidaId}/pista/${gameStatus.estado_partida["pistas"][0]}`}></Header>
            {/if}
        {/if}

        <Modal body header="Instruccions" isOpen={open} {toggle}>
            {@html gameStatus.instrucciones_juego} 
        </Modal>

        <div class="tab-content">
            <span class="label">{prueba.nombre}</span>
        </div>

        {#if !gameStatus.estado_pruebas[prueba.id]["estado"] && !resolved}
            
            <div class="letter">
                <p class="typewrite">{@html prueba.descripcion_prueba}</p>

            {#if prueba.tipo_flavour === 1 && prueba.fichero_flavour}
                <div>
                    <img class="img-polaroid" src=".{prueba.fichero_flavour}" alt="Imagen"/>
                </div>
            {/if}

            {#if prueba.tipo === 1}
                <div>
                    <img class="img-polaroid" src=".{prueba.fichero_soporte}" alt="Imagen Prueba"/>
                </div>
            {:else if prueba.tipo === 2}
                <div>
                    <audio controls> <source src="{prueba.fichero_soporte}"></audio>
                </div>
            {:else if prueba.tipo === 3}
                <div>
                    <video width="100%" controls> <source src="{prueba.fichero_soporte}" type="video/mp4"><track kind="captions"></video> 
                </div>
            {/if}
            </div>

            

            <div class="pistas">
                <span class="pistas-label">Pistes: </span>
                {#if gameStatus.estado_pruebas[prueba.id]["pistas"]}
                    {#each gameStatus.estado_pruebas[prueba.id]["pistas"] as pista} 
                        <a href="{`#/prueba/${prueba.id}/pista/${pista}`}" class="btn btn-secondary rounded-circle opened" role="button">
                            <i class="zmdi zmdi-email-open"></i>
                        </a>
                    {/each}
                {/if}
                {#if gameStatus.estado_pruebas[prueba.id]["next_pista"]}
                    <a href="{`#/prueba/${prueba.id}/pista/${gameStatus.estado_pruebas[prueba.id]["next_pista"]}`}" class="btn btn-secondary rounded-circle" role="button">
                        <i class="zmdi zmdi-email"></i>
                    </a>
                {/if}
                {#each pistasNoDisponibles as elemento}
                    <button class="btn btn-secondary rounded-circle pista" disabled>
                        <i class="zmdi zmdi-email"></i>
                    </button>
                {/each}
            </div>
            
            <div class="respuesta">
                <div class="error">
                    {#if is_wrong}
                        <p class="typewrite">Resposta incorrecta (+1 min). Torneu-ho a provar.</p>
                        {#if error_message}
                            <p class="typewrite">{error_message}</p>
                        {/if}
                    {/if}
                </div>
                
                <form on:submit|preventDefault={handleSubmit}>
                    <label>
                        {optional(prueba.pregunta)}
                        <input type="text" name="respuesta" maxlength="20" class="form-control" placeholder="Solució" required  />
                    </label>
                    <button class="send" type="submit">
                        <div class="circle">
                          <i class="zmdi zmdi-mail-send"></i>
                        </div>
                    </button>
                </form>
                <div style="height: {halfScreenHeight}px; text-align:center">
                    <button on:click|trusted={() => backToHome() } class="btn btn-street" type="button">
                        <i class="zmdi zmdi-arrow-left"></i> Tornar a l'arxiu del cas
                    </button>
                </div>
            </div>
            
            
        {:else}
            <div style="text-align:center; margin-bottom: 30px;">
                <button on:click|trusted={() => backToHome() } class="btn btn-street" type="button" style="margin-top:0px!important;">
                    <i class="zmdi zmdi-arrow-left"></i> Tornar a l'arxiu del cas
                </button>
            </div>
            <SolucionPrueba answer={ultimaRespuesta} message ={prueba.texto_solucion} url={`#/juego/${$partidaId}/#footer`}></SolucionPrueba>
            
        {/if}
    
    {:catch err}
        <b>{idPrueba}</b>
    {/await}
{:catch err}
	<b>{idPrueba}</b>
{/await}

<style>
    :global(body) {
        background-color: #fccc77;
        min-height: 100vh;
    }

    .tab-content {
        height: 40px;
        position: relative;
        top: 0px;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #f4f0e8;
        color: #5f606d;
        border-radius: 5px;
        padding: 4px 10px;  
        margin: 5px 5px 5px 5px;
        text-align: center;
        font-family: 'Handlee', cursive;
        font-size: 24px;
        z-index: -1;
    }

    .img-polaroid {
        display: block;
        margin: 0px 0px 0px -25px;
        max-width: 116%;
        transform: rotate(-2deg);
        border-radius: 5px;
        border: 10px solid #fff;
        box-shadow: 0 5px 10px rgba(0,0,0,0.3);
        padding: 5px;
        margin-bottom: 10px;
        background-color: #fff;
    }

    p.typewrite {
        font-family: 'Courier Prime', monospace;
        font-size: 12px;
    }

    .letter {
        background: #F4F0E8;
        box-shadow: 0 0 10px rgba(0,0,0,0.3);
        margin: 12px auto 0;
        /*max-width: 550px;*/
        min-height: 300px;
        padding: 24px;
        position: relative;
        width: 90%;
        margin-bottom: 14px;
    }

    .letter:before, .letter:after {
        content: "";
        height: 98%;
        position: absolute;
        width: 100%;
        z-index: -1;
    }

    .letter:before {
        background: #fafafa;
        box-shadow: 0 0 8px rgba(0,0,0,0.2);
        left: -5px;
        top: 4px;
        transform: rotate(-2.5deg);
    }
    
    .letter:after {
    background: #f6f6f6;
    box-shadow: 0 0 3px rgba(0,0,0,0.2);
    right: -3px;
    top: 1px;
    transform: rotate(1.4deg);
    }

    .send {
        background: transparent;
        border: 0;
        cursor: pointer;
        flex: 0 0 auto;
        margin-left: 8px;
        margin-right: 8px;
        padding: 0;
        position: relative;
        outline: none;
    }

    .send .circle {
        background: #008a7c;
        border-radius: 50%;
        color: #fff;
        position: relative;
        width: 48px;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .send .circle i {
        font-size: 24px;
        margin-left: 5px;
    }

    form {
        text-align: center;
    }

    .pistas {
        margin: 24px 15px 15px 15px;
    }

    .pistas-label {
        height: 40px;
        position: relative;
        top: 0px;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #f4f0e8;
        color: #5f606d;
        border-radius: 5px;
        padding: 4px 10px;  
        margin: 5px 5px 5px 5px;
        text-align: center;
        font-family: 'Handlee', cursive;
        font-size: 20px;
        font-style: italic;
        z-index: -1;
    }

    .pistas button{
        --bs-btn-border-color: transparent !important;
        margin-left: 5px;
    }
    .opened {
        background: #008a7c;
        --bs-btn-border-color: #008a7c !important;
        --bs-btn-hover-border-color: #008a7c !important;
    }
    a.opened {
        margin-left: 5px;
    }

    .error {
        margin-left: 20px;
        color: red;
        font-size: 12px;
    }

    .respuesta {
        margin-bottom:30px;
    }

    .btn-street {
        margin-top: 30px;
        color: #fff;
        background: #008a7c;
    }
</style>