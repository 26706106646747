<script>
	import Router, { link } from "svelte-spa-router";
	import { routes } from "../routes.js";
	import { onMount } from "svelte";

	let app;

	onMount(() => {
		app = document.querySelector('#app');
		
		window.onpopstate = () => {
		if (app) {
			app.scrollTo(0, 0);
		}
		};
	});


</script>

<link rel="preconnect" href="https://fonts.googleapis.com">
<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
<link href="https://fonts.googleapis.com/css2?family=Courier+Prime:wght@400;700&family=Handlee&family=Rowdies&family=Special+Elite&display=block" rel="stylesheet">

<main id="app">
	<Router {routes}/>
</main>

<div class="spacer"></div>
<footer class="footer" id="footer">
	<div class="container">
	  <span class="typewrite">&copy; 2023 <a href="https://escapemasnou.com">Escape Masnou, S.L.</a></span>
	</div>
</footer>

<style>
	.spacer {
		min-height: 50px;
	}
	.footer {
		position: fixed;
		margin:0;
		bottom: 0;
		width: 100%;
		height: 30px;
		line-height: 30px;
		background-color: #222222;
		color: #F2F2F2;
		font-family: 'Special Elite', cursive;
		font-size: 0.7rem;
		text-align: center;
		z-index: 5000;
	}

	.footer a {
		color: #F2F2F2;
		text-decoration: none;
		
	}
</style>