import App from './App.svelte';
import 'bootstrap';
import 'bootstrap-icons';
import 'bootstrap/dist/css/bootstrap.min.css';


const app = new App({
	target: document.body,
	
});

export default app;